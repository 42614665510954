import { Box, GlobalStyles, Stack } from '@mui/material';
import type { ErrorResponse } from '@remix-run/cloudflare';
import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { COLORS } from '@tbd/life-tokens';
import GlobalNavigationBar, {
  BAR_HEIGHT,
} from '~/components/GlobalNavigationBar';
import { LogoTitleLockup } from '~/components/LogoTitleLockup';
import assessmentIllustrationPath from '#app/life-assets/Illustrations/assessment_concept.svg';
import { createLogger } from '~/logging';

function HeroImage() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${assessmentIllustrationPath})`,
        backgroundSize: 'cover',
        width: '438px',
        aspectRatio: '210/138',
      }}
    />
  );
}

export function AnonymousLayout(props: {
  children: React.ReactNode;
  backgroundColor?: string;
}) {
  const { children, backgroundColor = COLORS.neutral_50 } = props;
  return (
    <>
      <GlobalStyles styles={{ body: { backgroundColor } }} />
      <Stack>
        <GlobalNavigationBar
          sx={{
            display: { xs: 'flex', xl: 'none' },
          }}
        />
        <Stack component="main" direction="row" justifyContent="center">
          <Stack
            flex={1}
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { xs: 'none', xl: 'flex' },
              backgroundColor: COLORS.accent1_500,
            }}
          >
            <LogoTitleLockup
              sx={{ mb: 4 }}
              textColor="white"
              logoVariant="outlined-white"
            />
            <HeroImage />
          </Stack>
          <Stack
            flex={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: { xs: `calc(100vh - ${BAR_HEIGHT})`, xl: '100vh' },
              maxWidth: '600px',
              padding: { xs: 2.5, xl: 3.5 },
              overflow: { xs: 'visible', xl: 'auto' },
            }}
          >
            <Stack sx={{ maxWidth: { xs: '400px' } }} flex={1}>
              {children}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const logger = createLogger('anonymous-error-boundary');
  // this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return handleErrorResponse(error);
  } else if (error instanceof Error) {
    logger.error(error);
    return handleError(error);
  }
  logger.debug('unknown error', error);
  return handleError();
}

function handleError(error?: Error) {
  return (
    <AnonymousLayout backgroundColor={COLORS.white}>
      <>
        <h1>An unexpected error occurred</h1>
        <p>
          It may work if you <a href="">try again</a>. Otherwise, go back to the{' '}
          <a href="/">home page</a>
        </p>
        {error?.stack ? (
          <>
            <hr />
            <small>
              <details>
                <summary>error details</summary>
                <pre>{error.stack}</pre>
              </details>
            </small>
          </>
        ) : null}
      </>
    </AnonymousLayout>
  );
}

function handleErrorResponse(response: ErrorResponse) {
  let message;
  switch (response.status) {
    case 401:
      message = (
        <p>
          Oops! Looks like you are not logged in.{' '}
          <a href="/login">Try logging in again</a>
        </p>
      );
      break;
    case 404:
      message = (
        <>
          <p>Oops! Looks like you tried to visit a page that does not exist.</p>
          <p>
            Go to the <a href="/">home page</a>
          </p>
        </>
      );
      break;

    default:
      throw new Error(response.data || response.statusText);
  }

  return (
    <AnonymousLayout backgroundColor={COLORS.white}>
      <h1>
        {response.status}: {response.statusText}
      </h1>
      {message}
    </AnonymousLayout>
  );
}
