import { SvgIcon, type SvgIconProps } from '@mui/material';
import type { LinksFunction } from '@remix-run/cloudflare';
import spriteHref from '#app/life-assets/LogosSprite.svg';
import type { IconName } from '@tbd/life-jsx/Logos';

export const getLogoLinks: LinksFunction = () => [
  {
    rel: 'preload',
    as: 'image',
    type: 'image/svg+xml',
    href: spriteHref,
  },
];

export function Logo({
  name,
  ...props
}: SvgIconProps & {
  name: IconName;
}) {
  return (
    <SvgIcon {...props}>
      <svg data-testid={`${name}Icon`}>
        <use href={`${spriteHref}#${name}`} />
      </svg>
    </SvgIcon>
  );
}
