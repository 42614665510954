import { Stack, type StackProps } from '@mui/material';
import { AppTitle } from './AppTitle';
import { LGHLogo } from './LGHLogo';
import { WhiteOutlinedLGHLogo } from './WhiteOutlinedLGHLogo';
import { VerticalDivider } from '@tbd/lift-ui/components/VerticalDivider';

export function LogoTitleLockup({
  sx,
  textColor,
  logoVariant,
}: {
  sx?: StackProps;
  textColor: 'black' | 'white';
  logoVariant: 'multi-colored' | 'outlined-white';
}) {
  const logo =
    logoVariant === 'multi-colored' ? <LGHLogo /> : <WhiteOutlinedLGHLogo />;
  const width = logoVariant === 'multi-colored' ? 1 : 2;
  return (
    <Stack direction={'row'} alignItems={'center'} {...sx}>
      <Stack justifyContent="center">{logo}</Stack>
      <Stack sx={{ padding: { xs: 1, md: 1.5 } }} justifyContent="center">
        <VerticalDivider width={width} color={textColor} />
      </Stack>
      <Stack>
        <AppTitle color={textColor} />
      </Stack>
    </Stack>
  );
}
