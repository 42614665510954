import { Fragment } from 'react';

// used LogoImmediate because a) we want it available right away and b) the linear gradient disappears when using the logo sprite
import {
  LogoLaerdalGlobalHealth,
  LghLogomarkColor,
} from '~/components/LogoImmediate.tsx';

export function LGHLogo() {
  return (
    <Fragment>
      <LogoLaerdalGlobalHealth
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '88px',
          height: '48px',
        }}
      />
      <LghLogomarkColor
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '32px',
          height: '32px',
        }}
      />
    </Fragment>
  );
}
