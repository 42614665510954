import { Logo } from '~/components/LogoDeferred.tsx';

export function WhiteOutlinedLGHLogo() {
  const width = 88;
  const height = 36;
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <Logo
      name="lgh-hollow-white-stroke"
      sx={{ width: `${width}px`, height: `${height}px` }}
      viewBox={viewBox}
    />
  );
}
