import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuList,
  Stack,
  type SxProps,
  type Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { BOXSHADOWS, COLORS } from '@tbd/life-tokens';
import type { AuthenticatedUser } from '~/auth.server';
import { isParticipant } from '~/auth.ts';
import { useRouteData as useRootData } from '~/root';
import {
  TextButton,
  textButtonClasses,
} from '@tbd/lift-ui/components/TextButton';
import { TypographyClampLines } from './TypographyClampLines';
import { Logout } from '~/components/SystemIconImmediate';
import { Hierarchy } from '~/components/SystemIconImmediate.tsx';

const BADGE_SIZE_SMALL = '36px';

type ProfileBadgeProps = {
  user: AuthenticatedUser | undefined;
  variant:
    | 'light-circle-no-outline'
    | 'light-circle-dark-outline'
    | 'dark-circle-no-outline'
    | 'light-green-circle-no-outline';
  avatarStyles?: SxProps<Theme>;
  typographyStyles?: SxProps<Theme>;
};

function ProfileBadge(props: ProfileBadgeProps) {
  const badgeSize = BADGE_SIZE_SMALL; // always using the small variant for now
  const { backgroundColor, color, borderColor, border } =
    getBadgeVariantProps(props);

  return (
    <Avatar
      sx={{
        width: badgeSize,
        height: badgeSize,
        backgroundColor,
        color,
        border,
        borderColor,
        ...(props.avatarStyles || {}),
      }}
    >
      <Typography
        variant="component-bold-xs"
        textTransform="uppercase"
        sx={{ ...(props.typographyStyles || {}) }}
      >
        {props.user?.firstName.toString()[0]}
        {props.user?.lastName.toString()[0]}
      </Typography>
    </Avatar>
  );
}

type ProfileMenuProps = {
  user: AuthenticatedUser;
  triggerVariant: ProfileBadgeProps['variant'];
};

function getBadgeVariantProps(props: ProfileBadgeProps) {
  if (props.variant === 'light-circle-dark-outline') {
    return {
      backgroundColor: COLORS.white,
      color: COLORS.accent1,
      borderColor: COLORS.accent1,
      border: '2px solid',
    };
  }

  if (props.variant === 'light-circle-no-outline') {
    return {
      backgroundColor: COLORS.white,
      color: COLORS.accent1,
    };
  }

  if (props.variant === 'light-green-circle-no-outline') {
    return {
      backgroundColor: COLORS.accent1_50,
      color: COLORS.accent1,
    };
  }
  // dark-circle-no-outline
  return {
    backgroundColor: COLORS.accent1,
    color: COLORS.white,
    borderColor: COLORS.white,
    border: undefined,
  };
}

export function ProfileMenu(props: ProfileMenuProps) {
  const rootData = useRootData();
  const theme = useTheme();
  const user = props.user;
  if (!user) return null;
  const userMenuId = 'menu-user';

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Box
        component="details"
        className="dropdown"
        sx={{
          position: 'relative',
          padding: '0',
          border: 'none',
          '&[open] > summary::before': {
            content: "' '",
            display: 'block',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          },
        }}
      >
        <IconButton
          component="summary"
          aria-label="Profile Information menu"
          aria-controls={userMenuId}
          aria-haspopup="true"
          sx={{
            /* Close the dropdown with outside clicks */
            '&::before': { display: 'none' },

            listStyle: 'none',
            listStyleType: 'none',
            '&::-webkit-details-marker': {
              display: 'none',
            },
            '&:focus': {
              outline: 'none',
            },
            padding: 0,
          }}
        >
          <ProfileBadge user={user} variant={props.triggerVariant} />
        </IconButton>
        <MenuList
          component="ul"
          id={userMenuId}
          sx={{
            '--badge-size': BADGE_SIZE_SMALL,
            '--menu-offset': '2px',
            '--menu-width': '320px',
            position: 'absolute',
            top: `calc(var(--badge-size) + var(--menu-offset))`,
            padding: '8px 0',
            width: 'var(--menu-width)',
            marginLeft:
              'calc( calc(var(--menu-width) - var(--badge-size)) * -1)',
            boxSizing: 'border-box',
            zIndex: '2',
            background: 'white',
            borderRadius: '6px',
            listStyle: 'none',
            boxShadow: BOXSHADOWS.BOXSHADOW_L1,
          }}
        >
          <Stack
            key="institution-row"
            component="li"
            role="menuitem"
            padding="0 8px"
          >
            <Typography
              variant="component-bold-xs"
              color={COLORS.neutral_700}
              padding="8px"
            >
              {user.institutionName}
            </Typography>
          </Stack>

          <Stack
            key="profile-row"
            component="li"
            role="menuitem"
            padding="0 8px"
          >
            <Stack
              direction="row"
              padding="6px 8px 16px 8px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box padding="2px 8px 2px 0px">
                <ProfileBadge
                  user={user}
                  variant="light-green-circle-no-outline"
                  typographyStyles={theme.typography['component-regular-xs']}
                />
              </Box>
              <Stack direction="row" flexGrow={1}>
                <Stack flexGrow="1">
                  <Typography
                    color={COLORS.neutral_800}
                    variant="component-regular-s"
                  >
                    {user?.firstName.toString()} {user?.lastName.toString()}
                  </Typography>
                  <TypographyClampLines
                    color={COLORS.neutral_500}
                    variant="component-regular-s"
                    lines={1}
                  >
                    {isParticipant(user) ? user?.userName : user?.email}
                  </TypographyClampLines>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider variant="fullWidth" />
          <Stack padding="8px 8px 0 8px">
            {rootData.clients.length > 1 ? (
              <Stack key="group-switcher" component="li" role="menuitem">
                <ProfileMenuItemButton
                  href="/login"
                  text="Switch group"
                  icon={<Hierarchy />}
                />
              </Stack>
            ) : null}
            <Stack key="logout-row" component="li" role="menuitem">
              <ProfileMenuItemButton
                href="/logout"
                icon={<Logout />}
                text={'Sign out'}
              />
            </Stack>
          </Stack>
        </MenuList>
      </Box>
    </Box>
  );
}
function ProfileMenuItemButton(props: {
  href: string;
  icon: React.ReactNode;
  text: string;
}) {
  return (
    <TextButton
      href={props.href}
      variant="tertiary"
      size="medium"
      sx={{
        justifyContent: 'flex-start',
        width: '100%',
        lineHeight: '48px',
        padding: '12px 8px',
        borderRadius: '6px',
        color: COLORS.neutral_700,
        ['&:hover']: {
          color: COLORS.accent1_600,
        },
        // TextButton (really MenuButton) adds margin to the icon, so we need to remove it
        [`&.${textButtonClasses.sizeMedium} > .${textButtonClasses.icon}`]: {
          marginLeft: '-2px',
        },
        [`& .${textButtonClasses.startIcon}`]: {
          width: '16px',
          marginRight: '10px', // 8px padding + 2px to compensate for the -2px margin above
        },
        // keep size/weight as-is but should probably be a variant instead
        '&': {
          height: '44px', // 20 + (12x2)
          fontSize: '16px',
          fontWeight: 400,
        },
      }}
      icon={props.icon}
      text={props.text}
    />
  );
}
