import { SvgIcon, type SvgIconProps } from '@mui/material';
import type { LinksFunction } from '@remix-run/cloudflare';
import spriteHref from '#app/life-assets/SystemIconSprite.svg';
import type { IconName } from '@tbd/life-jsx/SystemIcons';

export const getSystemIconDeferredLinks: LinksFunction = () => [
  {
    rel: 'preload',
    as: 'image',
    type: 'image/svg+xml',
    href: spriteHref,
  },
];

export function SystemIconDeferred({
  name,
  ...props
}: SvgIconProps & {
  name: IconName;
}) {
  return (
    <SvgIcon {...props}>
      <svg data-testid={`${name}Icon`}>
        <use href={`${spriteHref}#${name}`} />
      </svg>
    </SvgIcon>
  );
}
